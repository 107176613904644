import React from 'react'
import Layout from '../components/App/Layout'
import Navbar from '../components/Common/Navbar'
import PageBanner from '../components/Common/PageBanner'
import Footer from '../components/Common/Footer'
import BlogCard from '../components/BlogContent/BlogCard'
import { graphql, useStaticQuery } from 'gatsby'
import Pagination from '../components/Common/Pagination'

const query = graphql`
  {
    strapiSeos(slug: {eq: "blog"}) {
      Meta {
        description
        keywords
        title
      }
      TwitterMeta {
        card
      }
    }
    allStrapiBlog(skip: 0, limit: 10, sort: { fields: date, order: DESC }) {
      nodes {
        title
        slug
        content
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        categories {
          category
          slug
        }
        date: date(formatString: "MMMM D, YYYY")
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        pageCount
        currentPage
      }
    }
  }
`

const Blog = () => {
  const data = useStaticQuery(query)
  const {
    allStrapiBlog: { nodes: blogs, pageInfo },
  } = data

  const {
    strapiSeos: { Meta: metaTags, TwitterMeta },
  } = data
  
  return (
    <Layout metaTags={metaTags} twitterCard={TwitterMeta}>
      <Navbar />

      <PageBanner
        pageTitle="Blog"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Blog"
      />

      <section className="blog-area ptb-100">
        <div className="container">
          <div className="row">
            <BlogCard blogs={blogs} className="col-lg-6" />
          </div>
        </div>

        <Pagination pageInfo={pageInfo} />

      </section>

      <Footer />
    </Layout>
  )
}

export default Blog
